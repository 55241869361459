import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

const faviconLink =
    'https://static.wixstatic.com/ficons/fa0563_a3b164816bc14b3abc791db33e6d5fe6~mv2.ico';

interface ISeo {
    pageTitle: string;
    pageDescription: string;
    pageImage: any;
}

const SEO: FC<ISeo> = (props) => {
    return (
        <Helmet>
            <title>{props.pageTitle}</title>
            <link rel="icon" type="image/x-icon" href={faviconLink} />
            <meta name="title" content={props.pageTitle} />
            <meta name="description" content={props.pageDescription} />

            <meta property="og:type" content="website" />
            <meta property="og:title" content={props.pageTitle} />
            <meta property="og:description" content={props.pageDescription} />
            <meta property="og:image" content={props.pageImage} />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:title" content={props.pageTitle} />
            <meta property="twitter:description" content={props.pageDescription} />
            <meta property="twitter:image" content={props.pageImage} />

            <script
                type="text/javascript"
                id="hs-script-loader"
                async={true}
                defer={true}
                src="//js-eu1.hs-scripts.com/140395438.js"
            />
        </Helmet>
    );
};

export default SEO;
